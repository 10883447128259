import ReactDOM from 'react-dom/client';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { theme } from '~common/utils';
import App from './router';

// ErrorBoundary.jsx

const params = new URLSearchParams(window?.location?.search ?? '');
const assumedUserId = params.get('assume');

const isDev = import.meta.env.VITE_SENTRY_ENV !== 'production'
  && import.meta.env.VITE_SENTRY_ENV !== 'staging';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Caught by ErrorBoundary:', error, errorInfo);
    // eslint-disable-next-line react/no-unused-state
    this.setState({ errorInfo });
    // Optionally, send error info to your own logging endpoint
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <div>Something went wrong. Check console for details.</div>;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

if (!isDev) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
  });

  Sentry.setTag('assumeUserId', assumedUserId);
}

// Configure AWS Amplify.
Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
    cookieStorage: {
      domain: import.meta.env.VITE_COOKIE_DOMAIN,
      secure: import.meta.env.VITE_COOKIE_SECURE,
    },
  },
  API: {
    endpoints: [
      {
        name: import.meta.env.VITE_AWS_API_NAME,
        endpoint: import.meta.env.VITE_AWS_APIGATEWAY_URL,
        region: import.meta.env.VITE_AWS_REGION,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          ...(assumedUserId && { 'x-pe-assume-user-id': assumedUserId }),
        }),
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: import.meta.env.VITE_AWS_S3_UPLOAD_BUCKET_NAME,
      region: import.meta.env.VITE_AWS_REGION,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App assumedUserId={assumedUserId} />
      </ThemeProvider>
    </React.StrictMode>
  </ErrorBoundary>,
);
