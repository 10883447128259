// file: [appropriate_path]/PersonalDetailsFields.jsx
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import {
  FormTextField,
  FormAddressFields,
} from '~common/components';
import { dataLists, useAuth } from '~common/utils';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import MuiPhone from '~common/components/Phone/MuiPhone.jsx';

const { countryCodeOptions } = dataLists;

const PersonalDetailsFields = ({
  control,
  getValues,
  setValue,
  showAddressFields,
  showPhoneNumber,
}) => {
  const { user } = useAuth({
    Hub,
    Auth,
    bypassCache: false,
  });

  // For phone input, extract current prefix and number values similarly to previous implementations.
  const currentPrefixObj = getValues('phoneNumberPrefix');
  const currentPrefix = (currentPrefixObj && currentPrefixObj.value) || '';
  const currentNumber = getValues('phoneNumber') || '';
  const defaultCountry = currentPrefixObj && currentPrefixObj.id
    ? currentPrefixObj.id.toLowerCase()
    : 'gb';

  return (
    <>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="firstName"
            control={control}
            inputProps={{
              label: 'First Name',
              autoFocus: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="lastName"
            control={control}
            inputProps={{
              label: 'Last Name',
            }}
          />
        </Grid>
        {showPhoneNumber && (
          <Grid item xs={12} style={{ marginBottom: '5px', marginTop: '10px' }}>
            <MuiPhone
              country={defaultCountry}
              value={`+${currentPrefix}${currentNumber}`}
              containerStyle={{ marginBottom: '5px', marginTop: '10px', color: 'grey' }}
              inputStyle={{ marginBottom: '5px', marginTop: '10px' }}
              onChange={(data) => {
                const dialCode = `+${data.country.dialCode}`;
                // Determine correct country based on library provided country code
                let matchedOption = null;
                if (data.country.iso2) {
                  matchedOption = countryCodeOptions.find(
                    (option) => option.id.toLowerCase() === data.country.iso2.toLowerCase(),
                  );
                }
                // Fallback if country match not found using id, try matching dial code
                if (!matchedOption) {
                  matchedOption = countryCodeOptions.find(
                    (option) => option.value === dialCode,
                  );
                }
                if (matchedOption) {
                  setValue('phoneNumberPrefix', matchedOption);
                } else {
                  setValue('phoneNumberPrefix', { name: '', value: dialCode, id: '' });
                }
                // Remove prefix from value
                let numberWithoutPrefix = data.phone.replace(dialCode, '');
                // Remove any leading zeros if necessary
                numberWithoutPrefix = numberWithoutPrefix.replace(/^0+/, '');
                setValue('phoneNumber', numberWithoutPrefix);
              }}
              inputProps={{
                name: 'phone',
                required: true,
              }}
            />
          </Grid>
        )}
      </Grid>
      {showAddressFields && (
        <FormAddressFields
          control={control}
          getValues={getValues}
          setValue={setValue}
          userType={user.userType}
        />
      )}
    </>
  );
};

PersonalDetailsFields.defaultProps = {
  getValues: null,
  setValue: null,
  showAddressFields: true,
  showPhoneNumber: false,
};

PersonalDetailsFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  showPhoneNumber: PropTypes.bool,
  showAddressFields: PropTypes.bool,
};

export default PersonalDetailsFields;
