// file: apps/dashboard/src/components/editForm/components/parentLearnerFields.jsx
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import {
  FormTextField,
  FormLearnerInfoFields,
  FormAutocomplete,
  FormRadioGroup,
} from '~common/components';
import { dataLists } from '~common/utils';
import checkEmailExists from '@patheducation/admin/src/utils/checkEmail.js';
import { formatFieldName } from '@patheducation/login/src/components/registrationForm/helpers.js';
import MuiPhone from '~common/components/Phone/MuiPhone.jsx';
import useStyles from './styles';

const { countryCodeOptions } = dataLists;

// Email regex pattern
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+(\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ParentLearnerFields = ({
  control,
  getValues,
  setValue,
  setError,
  clearErrors,
  watch,
  type,
  trigger,
}) => {
  const { classes } = useStyles();
  const isNewLearner = type === 'parentLearnerCreate';

  const [noContactDetails, setNoContactDetails] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const email = useWatch({
    control,
    name: 'emailAddress',
  });

  const isValidEmail = (emailValue) => emailRegex.test(emailValue);

  const debouncedCheckEmail = useCallback(
    (() => {
      let timer;
      return (emailValue) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          if (isNewLearner && emailValue && isValidEmail(emailValue)) {
            try {
              const exists = await checkEmailExists(emailValue);
              setEmailExists(exists);
              if (exists) {
                setError('emailAddress', {
                  type: 'manual',
                  message: 'This email is already in use',
                });
              } else {
                clearErrors('emailAddress');
              }
            } catch (error) {
              console.error('Error checking email:', error);
              setError('emailAddress', {
                type: 'manual',
                message: 'Error checking email availability',
              });
            }
          } else if (isNewLearner && emailValue) {
            if (!isValidEmail(emailValue)) {
              setError('emailAddress', {
                type: 'manual',
                message: 'Please enter a valid email address',
              });
            } else {
              clearErrors('emailAddress');
            }
          } else {
            clearErrors('emailAddress');
            setEmailExists(false);
          }
        }, 500);
      };
    })(),
    [setError, clearErrors, isNewLearner],
  );

  useEffect(() => {
    if (!noContactDetails && isNewLearner) {
      debouncedCheckEmail(email);
    } else {
      clearErrors('emailAddress');
      setEmailExists(false);
    }
  }, [email, debouncedCheckEmail, noContactDetails, clearErrors, isNewLearner]);

  useEffect(() => {
    if (noContactDetails && isNewLearner) {
      setValue('emailAddress', '');
      setValue('phoneNumber', '');
      setValue('phoneNumberPrefix', '');
      setValue('noDetails', true);
      clearErrors('emailAddress');
      clearErrors('phoneNumber');
    }
  }, [noContactDetails, setValue, clearErrors, isNewLearner]);

  useEffect(() => {
    if (!isNewLearner && getValues('noDetails') === true) {
      setNoContactDetails(true);
    }
  }, [isNewLearner, setValue, getValues]);

  // Extract current prefix and number values correctly
  const currentPrefixObj = getValues('phoneNumberPrefix');
  const currentPrefix = (currentPrefixObj && currentPrefixObj.value) || '';
  const currentNumber = getValues('phoneNumber') || '';
  // Determine default country for PhoneInput based on stored prefix object
  // Fallback to UK ('gb') if not found or invalid to avoid defaulting to 'us'
  const defaultCountry = currentPrefixObj && currentPrefixObj.id
    ? currentPrefixObj.id.toLowerCase()
    : 'gb';

  return (
    <Grid container columnSpacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="firstName"
          control={control}
          inputProps={{
            label: 'First Name',
            autoFocus: true,
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="lastName"
          control={control}
          inputProps={{
            label: 'Last Name',
            required: true,
          }}
        />
      </Grid>

      {isNewLearner && (
        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={noContactDetails}
                onChange={(e) => setNoContactDetails(e.target.checked)}
                color="primary"
              />
            )}
            label="The learner does not have contact details"
          />
        </Grid>
      )}

      {((!isNewLearner && !noContactDetails) || (isNewLearner && !noContactDetails)) && (
        <>
          <Grid item xs={12}>
            <FormTextField
              name="emailAddress"
              control={control}
              inputProps={{
                label: 'Email Address',
                required: isNewLearner,
                readOnly: !isNewLearner,
              }}
              error={emailExists}
              helperText={emailExists ? 'This email is already in use' : ''}
            />
            {!isNewLearner && (
              <Typography variant="body2" className={classes.message}>
                Please note: If you need to update your email address please contact an administrator.
              </Typography>
            )}
          </Grid>
          {isNewLearner && (
            <Grid item xs={12}>
              <FormRadioGroup
                name="ownAccount"
                control={control}
                label="Would you like the student to have access to their own account using their own log in details?"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <MuiPhone
              country={defaultCountry}
              value={`+${currentPrefix}${currentNumber}`}
              containerStyle={{ marginBottom: '5px', marginTop: '10px', color: 'grey' }}
              inputStyle={{ marginBottom: '5px', marginTop: '10px' }}
              onChange={(data) => {
                const dialCode = `+${data.country.dialCode}`;
                // Determine correct country based on library provided country code
                let matchedOption = null;
                if (data.country.iso2) {
                  matchedOption = countryCodeOptions.find(
                    (option) => option.id.toLowerCase() === data.country.iso2.toLowerCase(),
                  );
                }
                // Fallback if country match not found using id, try matching dial code
                if (!matchedOption) {
                  matchedOption = countryCodeOptions.find(
                    (option) => option.value === dialCode,
                  );
                }
                if (matchedOption) {
                  setValue('phoneNumberPrefix', matchedOption);
                } else {
                  setValue('phoneNumberPrefix', { name: '', value: dialCode, id: '' });
                }
                // Remove prefix from value
                let numberWithoutPrefix = data.phone.replace(dialCode, '');
                // Remove any leading zeros if necessary
                numberWithoutPrefix = numberWithoutPrefix.replace(/^0+/, '');
                setValue('phoneNumber', numberWithoutPrefix);
              }}
              inputProps={{
                name: 'phone',
                required: true,
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <FormLearnerInfoFields
          type={type}
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          trigger={trigger}
        />
      </Grid>
    </Grid>
  );
};

ParentLearnerFields.defaultProps = {
  getValues: null,
  setValue: null,
  clearErrors: () => {},
  setError: () => {},
  trigger: null,
};

ParentLearnerFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
  trigger: PropTypes.func,
  watch: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['parentLearnerCreate', 'parentLearnerEdit']).isRequired,
};

export default ParentLearnerFields;
