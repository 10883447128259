import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Error, NotFound } from '~common/components';
import { useAuth } from '~common/utils';
import PageTemplate from '../components/pageTemplate';
import Dashboard from '../pages/dashboard';
import PrivateRoute from './PrivateRoute';
import AdminDashboard from '../pages/adminDashboard';
import useUser from './hooks/useUser';
import Lessons from '../pages/lessons';
import Notifications from '../pages/notifications';
import Profile from '../pages/profile';
import Payments from '../pages/payments';
import ScrollToTop from '../utils/ScrollToTop';

/* SimpleErrorBoundary component defined inline */
class SimpleErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details, optionally send to external service
    console.error('Error caught in SimpleErrorBoundary:lol', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    if (hasError) {
      return fallback;
    }
    return children;
  }
}

SimpleErrorBoundary.propTypes = {
  fallback: PropTypes.node,
  children: PropTypes.node,
};

SimpleErrorBoundary.defaultProps = {
  fallback: <div>Something went wrong.</div>,
  children: null,
};

function App({ assumedUserId }) {
  const { handleSignOut, user } = useAuth({ Hub, Auth, bypassCache: false });
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { userData, isAssumedMode, loading } = useUser({ assumedUserId });

  const routes = (
    <Router>
      {!loading && (
        <PageTemplate
          handleSignOut={handleSignOut}
          user={user}
          userData={userData}
          isAssumedMode={isAssumedMode}
          isMobileView={isMobileView}
        >
          <ScrollToTop />
          <Routes>
            <>
              <Route
                index
                path="/"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut} redirect={isMobileView ? '/lessons' : ''}>
                    {isMobileView
                      ? <Lessons userData={userData} />
                      : <Dashboard userData={userData} isMobileView={isMobileView} />}
                  </PrivateRoute>
                )}
                errorElement={<NotFound />}
              />
              <Route
                path="/dashboard"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Dashboard userData={userData} isMobileView={isMobileView} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/lessons"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Lessons userData={userData} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/notifications"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Notifications userData={userData} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/payments"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Payments userData={userData} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/payments/:paymentMonthId"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Payments userData={userData} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/profile"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Profile />
                  </PrivateRoute>
                )}
              />
              <Route
                path="/admin-dashboard"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <AdminDashboard user={user} />
                  </PrivateRoute>
                )}
              />
              <Route
                path="*"
                element={<NotFound isAuthenticated={user?.isAuthenticated} />}
              />
            </>
          </Routes>
        </PageTemplate>
      )}
    </Router>
  );

  const isDev = import.meta.env.VITE_SENTRY_ENV !== 'production'
    && import.meta.env.VITE_SENTRY_ENV !== 'staging';

  return isDev
    ? routes
    : (
      <SimpleErrorBoundary fallback={<Error />}>
        {routes}
      </SimpleErrorBoundary>
    );
}

App.defaultProps = {
  assumedUserId: null,
};

App.propTypes = {
  assumedUserId: PropTypes.string,
};

export default App;
